
import MTableHeaderCell from "@/components/MTableHeaderCell.vue";
import { TableDefinition } from "@/utils/table";
import { Options, Vue } from "vue-class-component";

function convertToData(
  definition: TableDefinition,
  sortKey: string,
  desc: boolean
): DataType {
  return {
    key: definition.key,
    text: definition.text,
    size: definition.size,
    maxSize: definition.maxSize,
    sortable: definition.sort !== "disable",
    select: definition.key === sortKey,
    desc,
    showOnMobile: definition.showOnMobile,
    isPinnedCol: definition.isPinnedCol ?? false
  };
}

type DataType = {
  key: string;
  text: string;
  size: number;
  maxSize?: string;
  sortable: boolean;
  select: boolean;
  desc: boolean;
  showOnMobile: boolean;
  isPinnedCol?: boolean;
};

@Options({
  components: {
    MTableHeaderCell
  },
  emits: ["change", "select"],
  props: {
    definitions: Array,
    sortKey: String,
    desc: Boolean,
    order: Boolean,
    hasLink: Boolean,
    selectable: Boolean,
    editable: Boolean,
    useActions: Boolean,
    useSubmission: Boolean,
    selectedAll: Boolean,
    paddingTop: {
      type: Number,
      default: 0
    }
  },
  watch: {
    definitions() {
      this.validateDefinitions();
    }
  }
})
export default class MTableHeader extends Vue {
  definitions: TableDefinition[] = [];
  sortKey = "";
  desc = false;
  order = false;
  hasLink = false;
  selectable = false;
  editable = false;
  useActions = false;
  useSubmission = false;
  selectedAll = false;
  paddingTop = 0;

  get datas(): DataType[] {
    return this.definitions.map(def =>
      convertToData(def, this.sortKey, this.desc)
    );
  }

  get sortedDatas(): { pinnedDatas: DataType[]; scrollableDatas: DataType[] } {
    if (!this.datas.length) return { pinnedDatas: [], scrollableDatas: [] };

    const targetIndex = this.datas.findIndex(d => d.isPinnedCol);
    if (targetIndex < 0) {
      // ピン止めの指定が無し
      return { pinnedDatas: [], scrollableDatas: [...this.datas] };
    } else {
      const pinnedDatas: DataType[] = [...this.datas].slice(0, targetIndex + 1);
      const scrollableDatas: DataType[] = [...this.datas].slice(
        targetIndex + 1
      );
      return { pinnedDatas, scrollableDatas };
    }
  }

  get hasPinnedColumn(): boolean {
    return this.sortedDatas.pinnedDatas.length > 0;
  }

  change(data: DataType) {
    if (!data.sortable) {
      return;
    }

    this.$emit("change", data.key);
  }

  select() {
    this.$emit("select");
  }

  validateDefinitions() {
    // 固定する列関連でdefinitionに不正な値があれば警告を出力する
    if (window.location.hostname !== "localhost") return;
    let pinnedCount = 0;
    let sizeNoneCount = 0;
    this.definitions.forEach(d => {
      if (!d.size) sizeNoneCount++;
      if (d.isPinnedCol) {
        if (sizeNoneCount) {
          console.warn(
            `The column configuration is invalid: any column before 'isPinnedCol' must have a valid 'width' property. Without a valid width, the table layout may break, causing columns to misalign or render incorrectly. `
          );
        }
        pinnedCount++;
      }
    });
    if (pinnedCount > 1) {
      console.warn(
        `Duplicate 'isPinnedCol' properties detected in the table definitions. Each table definition array can have at most one 'isPinnedCol' property. If multiple properties are defined, only the first 'isPinnedCol' will be used, and all subsequent instances will be ignored.`
      );
    }
  }

  created() {
    this.validateDefinitions();
  }
}
