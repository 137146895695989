import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_table_header = _resolveComponent("m-table-header")
  const _component_m_table_contents = _resolveComponent("m-table-contents")
  const _component_m_table_footer = _resolveComponent("m-table-footer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_m_table_header, {
      definitions: _ctx.filteredDefinitions,
      "sort-key": _ctx.sortKey,
      desc: _ctx.sortIsDesc,
      order: _ctx.order,
      "has-link": _ctx.hasLink,
      selectable: _ctx.selectable,
      editable: _ctx.editable,
      "use-actions": _ctx.useActions,
      "use-submission": _ctx.useSubmission,
      "selected-all": _ctx.selectedAll,
      "padding-top": _ctx.paddingTop,
      onChange: _ctx.changeSort,
      onSelect: _ctx.changeSelectAll
    }, null, 8, ["definitions", "sort-key", "desc", "order", "has-link", "selectable", "editable", "use-actions", "use-submission", "selected-all", "padding-top", "onChange", "onSelect"]),
    _createVNode(_component_m_table_contents, {
      datas: _ctx.filteredDatas,
      definitions: _ctx.filteredDefinitions,
      "selected-ids": _ctx.selectedIds,
      order: _ctx.order,
      selectable: _ctx.selectable,
      editable: _ctx.editable,
      "use-actions": _ctx.useActions,
      "use-submission": _ctx.useSubmission,
      "colored-row-list": _ctx.coloredRowList,
      onChange: _ctx.change,
      onSelect: _ctx.select,
      onCopy: _ctx.copy
    }, null, 8, ["datas", "definitions", "selected-ids", "order", "selectable", "editable", "use-actions", "use-submission", "colored-row-list", "onChange", "onSelect", "onCopy"]),
    _createVNode(_component_m_table_footer, {
      start: _ctx.startNum,
      end: _ctx.endNum,
      max: _ctx.maxNum,
      onBack: _ctx.backPagination,
      onNext: _ctx.nextPagination
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "footer")
      ]),
      _: 3
    }, 8, ["start", "end", "max", "onBack", "onNext"])
  ]))
}