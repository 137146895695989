import { TutorMeta } from "@/api/tutor";
import { Classroom } from "@/entities/classroom";
import { Group } from "@/entities/group";
import {
  MessageRegularlyReservation,
  messageRegularlyReservationCollectionKey
} from "@/entities/message_regularly_reservation";
import {
  MessageReservation,
  messageReservationCollectionKey
} from "@/entities/message_reservation";
import { Registrant } from "@/entities/registrant";
import { Room } from "@/entities/room";
import {
  RoomRegularlyReservation,
  roomRegularlyReservationCollectionKey
} from "@/entities/room_regularly_reservation";
import {
  RoomReservation,
  roomReservationCollectionKey
} from "@/entities/room_reservation";
import { School, SchoolConfig } from "@/entities/school";
import { Student, StudentSecret } from "@/entities/student";
import { Tutor } from "@/entities/tutor";
import dayjs from "dayjs";
import {
  getWeekdayStr,
  convertToDateFromUnixtimeSlash,
  convertToTimeFromUnixtime
} from "./date";
import { Target } from "@/entities/target";

export type TableDataType =
  | "string"
  | "number"
  | "boolean"
  | "unixtime"
  | "none";

export type TableSortType = "normal" | "prefix" | "suffix" | "disable";

export type TableData = { [key: string]: string | number | boolean };

export type TableDefinition = {
  text: string;
  key: string;
  type: TableDataType;
  subType?: "original";
  prefix: boolean;
  prefixType: TableDataType;
  suffix: boolean;
  suffixType: TableDataType;
  sort: TableSortType;
  size: number;
  maxSize?: string;
  showOnMobile: boolean;
  extraClass?: string;
  hover?: boolean;
  copyable?: boolean;
  isPinnedCol?: boolean; // これがtrueの時は、幅の指定が必須
};

export function convertToClassroomTableData(classroom: Classroom): TableData {
  return {
    id: classroom.ref.id,
    name: classroom.data.name,
    "suf-name": classroom.data.id,
    studentNum: classroom.data.studentCount
  };
}

export function convertToGroupTableData(group: Group): TableData {
  let sufName: string;
  if (group.data.filters.length === 0) {
    sufName = `選択された生徒${group.data.students.length}人`;
  } else {
    sufName = `${group.data.filters.length}つの条件に該当する生徒`;
  }

  return {
    id: group.ref.id,
    name: group.data.title,
    "suf-name": sufName,
    studentNum: group.data.result.length
  };
}

export function convertToMessageReservationTableData(
  reservation: MessageReservation,
  groups: Group[],
  schools: School[],
  classrooms: Classroom[]
): TableData {
  let target: string;
  if (reservation.data.to.type === "all") {
    target = "全ての生徒";
  } else if (
    reservation.data.to.type === "group" &&
    reservation.data.to.group
  ) {
    const matchGroups = groups.filter(
      group => reservation.data.to.group!.id === group.ref.id
    );
    if (matchGroups.length > 0) {
      target = matchGroups[0].data.title;
    } else {
      target = "-";
    }
  } else if (
    reservation.data.to.type === "school" &&
    reservation.data.to.school
  ) {
    const matchSchools = schools.filter(
      school => reservation.data.to.school!.id === school.ref.id
    );
    if (matchSchools.length > 0) {
      target = matchSchools[0].data.name;
    } else {
      target = "-";
    }
  } else if (
    reservation.data.to.type === "classroom" &&
    reservation.data.to.classroom
  ) {
    const matchClassrooms = classrooms.filter(
      classroom => reservation.data.to.classroom!.id === classroom.ref.id
    );
    if (matchClassrooms.length > 0) {
      target = matchClassrooms[0].data.name;
    } else {
      target = "-";
    }
  } else if (
    reservation.data.to.type === "students" &&
    reservation.data.to.students
  ) {
    target = `${reservation.data.to.students.length}人の生徒`;
  } else {
    target = "-";
  }

  let content: string;
  if (reservation.data.messageType === "image") {
    content = "画像を送信";
  } else if (reservation.data.messageType === "text") {
    let messageArray = reservation.data.messageText!.split("みんがく管理人:\n");
    messageArray = messageArray[messageArray.length - 1].split("先生:\n");
    content = messageArray[messageArray.length - 1];
  } else if (reservation.data.messageType === "multiple") {
    let messageArray = reservation.data
      .messageContents!.filter(c => c.type === "text")[0]
      .text.split("みんがく管理人:\n");
    messageArray = messageArray[messageArray.length - 1].split("先生:\n");
    content = messageArray[messageArray.length - 1];
  } else {
    content = "";
  }

  const time = dayjs(reservation.data.reservationTime * 1000)
    .locale("ja")
    .format("YYYY/MM/DD HH:mm");

  return {
    id: messageReservationCollectionKey + "/" + reservation.ref.id,
    target,
    content,
    time,
    "suf-time": ""
  };
}

export function convertToMessageRegularlyReservationTableData(
  reservation: MessageRegularlyReservation,
  groups: Group[],
  schools: School[],
  classrooms: Classroom[]
): TableData {
  let target: string;
  if (reservation.data.to.type === "all") {
    target = "全ての生徒";
  } else if (
    reservation.data.to.type === "group" &&
    reservation.data.to.group
  ) {
    const matchGroups = groups.filter(
      group => reservation.data.to.group!.id === group.ref.id
    );
    if (matchGroups.length > 0) {
      target = matchGroups[0].data.title;
    } else {
      target = "-";
    }
  } else if (
    reservation.data.to.type === "school" &&
    reservation.data.to.school
  ) {
    const matchSchools = schools.filter(
      school => reservation.data.to.school!.id === school.ref.id
    );
    if (matchSchools.length > 0) {
      target = matchSchools[0].data.name;
    } else {
      target = "-";
    }
  } else if (
    reservation.data.to.type === "classroom" &&
    reservation.data.to.classroom
  ) {
    const matchClassrooms = classrooms.filter(
      classroom => reservation.data.to.classroom!.id === classroom.ref.id
    );
    if (matchClassrooms.length > 0) {
      target = matchClassrooms[0].data.name;
    } else {
      target = "-";
    }
  } else if (
    reservation.data.to.type === "students" &&
    reservation.data.to.students
  ) {
    target = `${reservation.data.to.students.length}人の生徒`;
  } else {
    target = "-";
  }

  let content: string;
  if (reservation.data.messageType === "image") {
    content = "画像を送信";
  } else if (reservation.data.messageType === "text") {
    let messageArray = reservation.data.messageText!.split("みんがく管理人:\n");
    messageArray = messageArray[messageArray.length - 1].split("先生:\n");
    content = messageArray[messageArray.length - 1];
  } else if (reservation.data.messageType === "multiple") {
    let messageArray = reservation.data
      .messageContents!.filter(c => c.type === "text")[0]
      .text.split("みんがく管理人:\n");
    messageArray = messageArray[messageArray.length - 1].split("先生:\n");
    content = messageArray[messageArray.length - 1];
  } else {
    content = "";
  }

  const time = dayjs(reservation.data.nextTime * 1000)
    .locale("ja")
    .format("YYYY/MM/DD HH:mm");

  let sufTime: string;
  if (reservation.data.reservationType === "day") {
    sufTime = `[毎日 ${reservation.data.time}]`;
  } else if (
    reservation.data.reservationType === "week" &&
    reservation.data.weekday !== undefined
  ) {
    const weekStr = getWeekdayStr(reservation.data.weekday);
    sufTime = `[毎週${weekStr}曜日 ${reservation.data.time}]`;
  } else if (
    reservation.data.reservationType === "month" &&
    reservation.data.monthday !== undefined
  ) {
    sufTime = `[毎月${reservation.data.monthday}日 ${reservation.data.time}]`;
  } else {
    sufTime = "";
  }

  return {
    id: messageRegularlyReservationCollectionKey + "/" + reservation.ref.id,
    target,
    content,
    time,
    "suf-time": sufTime
  };
}

export function convertToRoomReservationTableData(
  reservation: RoomReservation
): TableData {
  const time = dayjs(reservation.data.reservationTime * 1000)
    .locale("ja")
    .format("YYYY/MM/DD HH:mm");

  return {
    id: roomReservationCollectionKey + "/" + reservation.ref.id,
    name: reservation.data.name,
    group: reservation.data.groupName,
    time,
    "suf-time": reservation.data.end
      ? `${convertToDateFromUnixtimeSlash(
          reservation.data.end
        )} ${convertToTimeFromUnixtime(reservation.data.end)} 閉室`
      : ""
  };
}

export function convertToRoomRegularlyReservationTableData(
  reservation: RoomRegularlyReservation
): TableData {
  const time = dayjs(reservation.data.nextTime * 1000)
    .locale("ja")
    .format("YYYY/MM/DD HH:mm");

  let sufTime: string;
  if (reservation.data.reservationType === "day") {
    sufTime = `毎日 ${reservation.data.time}開室 ${reservation.data.end}閉室`;
  } else if (
    reservation.data.reservationType === "week" &&
    reservation.data.weekday !== undefined
  ) {
    const weekStr = getWeekdayStr(reservation.data.weekday);
    sufTime = `毎週${weekStr}曜日 ${reservation.data.time}開室 ${reservation.data.end}閉室`;
  } else if (
    reservation.data.reservationType === "month" &&
    reservation.data.monthday !== undefined
  ) {
    sufTime = `毎月${reservation.data.monthday}日 ${reservation.data.time}開室 ${reservation.data.end}閉室`;
  } else {
    sufTime = "";
  }

  return {
    id: roomRegularlyReservationCollectionKey + "/" + reservation.ref.id,
    name: reservation.data.name,
    group: reservation.data.groupName,
    time,
    "suf-time": sufTime
  };
}

export function convertToRegistrantTableData(
  registrant: Registrant,
  students: Student[],
  studentSecrets: StudentSecret[],
  rooms: Room[],
  schools: School[],
  classrooms: Classroom[]
): TableData {
  const matchStudents = students.filter(
    item => item.ref.id === registrant.data.student.id
  );
  const student = matchStudents.length > 0 ? matchStudents[0] : null;
  const classroomId = student ? student.ref.parent.parent!.id : "";
  const schoolId = student ? student.ref.parent.parent!.parent.parent!.id : "";
  const matchStudentSecrets = studentSecrets.filter(
    item => item.ref.id === registrant.data.student.id
  );
  const studentSecret =
    matchStudentSecrets.length > 0 ? matchStudentSecrets[0] : null;
  const matchSchools = schools.filter(item => item.ref.id === schoolId);
  const school = matchSchools.length > 0 ? matchSchools[0] : null;
  const matchClassrooms = classrooms.filter(
    item => item.ref.id === classroomId
  );
  const classroom = matchClassrooms.length > 0 ? matchClassrooms[0] : null;
  const matchRooms = rooms.filter(
    item => item.ref.id === registrant.ref.parent.parent!.id
  );
  const room = matchRooms.length > 0 ? matchRooms[0] : null;

  let status = "なし";
  if (registrant.data.cameraStatus) {
    if (registrant.data.cameraStatus === "use_device") {
      status = "1: 端末を使った学習";
    } else if (registrant.data.cameraStatus === "short_break") {
      status = "2: 5分未満の休憩";
    } else if (registrant.data.cameraStatus === "connection_practice") {
      status = "3: 接続練習のため";
    } else if (registrant.data.cameraStatus === "device_defect") {
      status = "4: 端末の不調";
    } else if (registrant.data.cameraComment) {
      status = `5: ${registrant.data.cameraComment}`;
    } else {
      status = "5: その他";
    }
  }
  return {
    id: registrant.ref.id,
    link: student
      ? `/student/${schoolId}/${classroomId}/${student.ref.id}/profile`
      : "",
    name: student ? student.data.name : "-",
    "suf-name": school && student ? `${school.data.id}-${student.data.id}` : "",
    school: school ? school.data.name : "-",
    classroom: classroom ? classroom.data.name : "-",
    room: room ? room.data.name : "-",
    comment: studentSecret ? studentSecret.data.comment : "",
    status
  };
}

export async function convertToSchoolTableData(
  school: School,
  owners: Tutor[],
  schoolConfigs: SchoolConfig[],
  targets: Target[]
): Promise<TableData> {
  const matchOwner = owners.find(
    owner => owner.ref.parent.parent!.id === school.ref.id
  );
  const owner = matchOwner?.data.name ?? "-";

  const schoolConfig = schoolConfigs.find(
    config => config.ref.id === school.ref.id
  );
  const status = !schoolConfig?.data.recessTime ? "有効" : "休会";

  const paymentTypeKey = schoolConfig?.data.paymentType ?? "none";
  const role: string = (schoolConfig?.data.role ?? [])
    .map(role => {
      return role === "serviceProvider" ? "サービス事業者" : role ? role : "-";
    })
    .join(",");
  const hideLoginInvitation = schoolConfig?.data.hideLoginInvitaion ?? false;
  const hideMingakuStudyRoom = schoolConfig?.data.hideMingakuStudyRoom ?? false;
  const allowStudentsToChangePassword =
    schoolConfig?.data.allowStudentsToChangePassword ?? false;
  const useSchoolAiExclusively =
    schoolConfig?.data.useSchoolAiExclusively ?? false;
  const allowUseOfCustomOfficialLine =
    schoolConfig?.data.allowUseOfCustomOfficialLine ?? false;
  const email = schoolConfig?.data.customerEmail || "-";
  let paymentType = "";
  if (paymentTypeKey === "none") {
    paymentType = "デモ・トライアル期間";
  } else if (paymentTypeKey === "external") {
    paymentType = "外部決済";
  } else if (paymentTypeKey === "stripe") {
    paymentType = "クレジットカード";
  } else if (paymentTypeKey === "uncontracted") {
    paymentType = "利用停止中";
  }

  const allowCreateRoom = schoolConfig?.data.allowCreateRoom ? "ON" : "OFF";
  const activateTimeUnix = schoolConfig?.data.activateTime;
  const activateTime =
    activateTimeUnix !== undefined
      ? dayjs.unix(activateTimeUnix).format("YYYY/MM/DD")
      : "未設定";
  const target =
    targets.find(t => t.ref.id === schoolConfig?.data.target.id)?.data.name ??
    "-";

  return {
    id: school.ref.id,
    link: `/student?school=${school.ref.id}`,
    schoolId: school.data.id,
    name: school.data.name,
    "suf-name": school.data.id,
    owner,
    email,
    classroomNum: school.data.classroomCount,
    studentNum: school.data.studentCount,
    paymentType,
    allowCreateRoom,
    activateTime,
    target,
    status,
    role: role ? role : "-",
    hideLoginInvitation: !hideLoginInvitation ? "有効" : "無効",
    hideMingakuStudyRoom: !hideMingakuStudyRoom ? "有効" : "無効",
    useSchoolAiExclusively: useSchoolAiExclusively ? "有効" : "無効",
    allowUseOfCustomOfficialLine: allowUseOfCustomOfficialLine
      ? "有効"
      : "無効",
    allowStudentsToChangePassword: allowStudentsToChangePassword
      ? "有効"
      : "無効"
  };
}

export function convertToTutorTableData(
  tutor: TutorMeta,
  classrooms: Classroom[],
  { isSchool }: { isSchool: boolean } = { isSchool: false }
): { [key: string]: string | number | boolean } {
  let authority: string;
  if (tutor.config.data.authority === "owner") {
    authority = isSchool ? "管理職" : "オーナー";
  } else if (tutor.config.data.authority === "admin") {
    authority = isSchool ? "ICT担当者" : "管理者";
  } else {
    let authorityName = "一般";
    if (tutor.config.data.authority === "supervisor") {
      authorityName = "責任者";
    } else if (tutor.config.data.authority === "employee") {
      authorityName = isSchool ? "先生" : "担当者";
    }
    const authorizedClassrooms: Classroom[] = [];
    tutor.config.data.classrooms.forEach(ref => {
      const matchClassrooms = classrooms.filter(
        classroom => classroom.ref.id === ref.id
      );
      if (matchClassrooms.length > 0) {
        authorizedClassrooms.push(matchClassrooms[0]);
      }
    });
    if (authorizedClassrooms.length > 0) {
      const classroomTexts = authorizedClassrooms
        .map(item => item.data.name)
        .join(", ");
      authority = `${authorityName} (${authorizedClassrooms.length}教室: ${classroomTexts})`;
    } else {
      authority = `${authorityName} (0教室)`;
    }
  }
  return {
    id: tutor.main.ref.id,
    name: tutor.main.data.name,
    authority
  };
}

export function formatUnixtime(unixtime: number): string {
  if (unixtime === 0) {
    return "";
  }

  const now = dayjs().locale("ja");
  const messageTime = dayjs(unixtime * 1000).locale("ja");
  if (now.year() !== messageTime.year()) {
    return messageTime.format("YYYY/M/D HH:mm");
  } else if (
    now.month() === messageTime.month() &&
    now.date() === messageTime.date()
  ) {
    return "今日 " + messageTime.format("HH:mm");
  } else if (
    now.month() === messageTime.month() &&
    now.date() === messageTime.date() + 1
  ) {
    return "昨日 " + messageTime.format("HH:mm");
  } else {
    return messageTime.format("M/D HH:mm");
  }
}

export const classroomsTableDefinition: TableDefinition[] = [
  {
    text: "教室名",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "suffix",
    size: 0,
    showOnMobile: true
  },
  {
    text: "生徒数",
    key: "studentNum",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 24,
    showOnMobile: true
  }
];

export const groupsTableDefinition: TableDefinition[] = [
  {
    text: "グループ名",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "disable",
    size: 0,
    showOnMobile: true
  },
  {
    text: "対象生徒数",
    key: "studentNum",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 32,
    showOnMobile: true
  }
];

export const messageReservationsTableDefinition: TableDefinition[] = [
  {
    text: "対象",
    key: "target",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 0,
    showOnMobile: false
  },
  {
    text: "メッセージ内容",
    key: "content",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 0,
    showOnMobile: true
  },
  {
    text: "送信予定日時",
    key: "time",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "normal",
    size: 0,
    showOnMobile: true
  }
];

export const roomReservationsTableDefinition: TableDefinition[] = [
  {
    text: "学習室名",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 0,
    showOnMobile: true
  },
  {
    text: "対象グループ",
    key: "group",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 0,
    showOnMobile: false
  },
  {
    text: "送信予定日時",
    key: "time",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "normal",
    size: 0,
    showOnMobile: true
  }
];

const registrantNameDef: TableDefinition = {
  text: "プロフィール",
  key: "name",
  type: "string",
  prefix: false,
  prefixType: "none",
  suffix: true,
  suffixType: "string",
  sort: "suffix",
  size: 0,
  showOnMobile: true
};
const roomDef: TableDefinition = {
  text: "学習室",
  key: "room",
  type: "string",
  prefix: false,
  prefixType: "none",
  suffix: false,
  suffixType: "none",
  sort: "normal",
  size: 0,
  showOnMobile: true
};

export const registrantsOfRoomAdminTableDefinition: TableDefinition[] = [
  registrantNameDef,
  {
    text: "所属スクール",
    key: "school",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 0,
    showOnMobile: true
  },
  {
    text: "カメラオフの可能性",
    key: "status",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 0,
    showOnMobile: false
  },
  {
    text: "管理人メモ",
    key: "comment",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 0,
    showOnMobile: false
  }
];

export const registrantsOfRoomTutorTableDefinition: TableDefinition[] = [
  registrantNameDef,
  {
    text: "所属教室",
    key: "classroom",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 0,
    showOnMobile: true
  }
];

export const registrantsOfAdminTableDefinition: TableDefinition[] = [
  ...registrantsOfRoomAdminTableDefinition,
  roomDef
];

export const registrantsOfTutorTableDefinition: TableDefinition[] = [
  ...registrantsOfRoomTutorTableDefinition,
  roomDef
];

export const schoolsTableDefinition: TableDefinition[] = [
  {
    text: "スクールID",
    key: "schoolId",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "normal",
    size: 20,
    showOnMobile: true
  },
  {
    text: "スクールデータ",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "suffix",
    size: 40,
    showOnMobile: true,
    hover: true
  },
  {
    text: "スクールオーナー",
    key: "owner",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 20,
    showOnMobile: false,
    hover: true
  },
  {
    text: "メールアドレス",
    key: "email",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 40,
    showOnMobile: false,
    hover: true,
    copyable: true,
    isPinnedCol: true
  },
  {
    text: "教室数",
    key: "classroomNum",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "生徒数",
    key: "studentNum",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "支払方法",
    key: "paymentType",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 24,
    showOnMobile: false,
    hover: true
  },
  {
    text: "独自学習室",
    key: "allowCreateRoom",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 24,
    showOnMobile: false
  },
  {
    text: "利用開始日",
    key: "activateTime",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 32,
    showOnMobile: false
  },
  {
    text: "スクール領域",
    key: "target",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false,
    hover: true
  },
  {
    text: "状態",
    key: "status",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "役割",
    key: "role",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false,
    hover: true
  },
  {
    text: "スクールAIのみ利用",
    key: "useSchoolAiExclusively",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "独自公式LINEを利用",
    key: "allowUseOfCustomOfficialLine",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "生徒帳票表示",
    key: "hideLoginInvitation",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "みんがく学習室を表示しない",
    key: "hideMingakuStudyRoom",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "生徒のパスワード変更を許可",
    key: "allowStudentsToChangePassword",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 20,
    showOnMobile: false
  }
];

export const miniAppsTableDefinition: TableDefinition[] = [
  {
    text: "独自アプリの名称",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 40,
    showOnMobile: true
  },
  {
    text: "独自アプリのURL",
    key: "url",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 80,
    showOnMobile: false,
    hover: true
  },
  {
    text: "独自アプリの表示順",
    key: "displayOrder",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 40,
    showOnMobile: false
  },
  {
    text: "アプリ内で開く",
    key: "isWebView",
    type: "string", //ソートの際には「有/無」でソートがかかるため、stingにする
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 40,
    showOnMobile: false
  },
  {
    text: "独自アプリのカテゴリ",
    key: "serviceType",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 40,
    showOnMobile: false
  },
  {
    text: "アプリ内での表示/非表示",
    key: "isDisplayNone",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 40,
    showOnMobile: false
  }
];

export const tutorsTableDefinition: TableDefinition[] = [
  {
    text: "プロフィール",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 0,
    showOnMobile: true
  },
  {
    text: "権限",
    key: "authority",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 0,
    showOnMobile: true
  }
];

export const studentsTableDefinition: TableDefinition[] = [
  {
    text: "学習中",
    key: "realtime",
    type: "string",
    prefix: false,
    prefixType: "string",
    suffix: false,
    suffixType: "string",
    sort: "normal",
    size: 24,
    showOnMobile: false,
    extraClass: "text-xl"
  },
  {
    text: "ログインID",
    key: "suf-name",
    type: "string",
    prefix: false,
    prefixType: "string",
    suffix: false,
    suffixType: "string",
    sort: "normal",
    size: 36,
    showOnMobile: false
  },
  {
    text: "ユーザーネーム",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "string",
    suffix: false,
    suffixType: "string",
    sort: "normal",
    size: 36,
    showOnMobile: true,
    isPinnedCol: true
  },
  {
    text: "教室",
    key: "classroom",
    type: "string",
    prefix: false,
    prefixType: "string",
    suffix: false,
    suffixType: "string",
    sort: "normal",
    size: 20,
    showOnMobile: false
  },
  {
    text: "学年",
    key: "grade",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 20,
    showOnMobile: false
  },
  {
    text: "スクールAI",
    key: "schoolAILinkageStatus",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 32,
    showOnMobile: false
  },
  {
    text: "授業曜日",
    key: "weekdays",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 28,
    showOnMobile: false
  },
  {
    text: "入室時間＊",
    key: "time",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 32,
    showOnMobile: false
  },
  {
    text: "学習時間",
    key: "totalTime",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 28,
    showOnMobile: false
  },
  {
    text: "入室日数＊",
    key: "dayCount",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 32,
    showOnMobile: false
  },
  {
    text: "学習日数",
    key: "totalDayCount",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 28,
    showOnMobile: false
  },
  {
    text: "最新入室時刻＊",
    key: "latestEntranceTimeLabel",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 28,
    showOnMobile: false
  },
  {
    text: "最新学習開始時刻",
    key: "totalLatestEntranceTimeLabel",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 28,
    showOnMobile: false
  },
  {
    text: "状態",
    key: "recessTime",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 16,
    showOnMobile: false
  },
  {
    text: "最新メッセージ",
    key: "message",
    type: "string",
    prefix: true,
    prefixType: "unixtime",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 72,
    showOnMobile: false
  }
];

export const qandaQuestionsDefinitions: TableDefinition[] = [
  {
    text: "質問ID",
    key: "id",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 0,
    showOnMobile: true
  },
  {
    text: "タイプ",
    key: "type",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 0,
    showOnMobile: true
  },
  {
    text: "質問時刻",
    key: "createTime",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 0,
    showOnMobile: false
  }
];

export const submissionMasterDetailDefinitions: TableDefinition[] = [
  {
    text: "名前",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "suffix",
    size: 36,
    showOnMobile: true
  },
  {
    text: "学年",
    key: "grade",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 20,
    showOnMobile: false
  },
  {
    text: "進捗",
    key: "status",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 36,
    showOnMobile: true
  },
  {
    text: "最新提出日",
    key: "submittedAtDate",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 32,
    showOnMobile: false
  },
  {
    text: "最新提出時刻",
    key: "submittedAtTime",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 32,
    showOnMobile: false
  },
  {
    text: "確認日",
    key: "checkedAt",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 32,
    showOnMobile: false
  },
  {
    text: "コメント",
    key: "comment",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 72,
    showOnMobile: false
  }
];

export const threadMasterDetailDefinitions: TableDefinition[] = [
  {
    text: "名前",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: true,
    suffixType: "string",
    sort: "suffix",
    size: 36,
    showOnMobile: true
  },
  {
    text: "学年",
    key: "grade",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 20,
    showOnMobile: false
  },
  {
    text: "最新提出日",
    key: "submittedAtDate",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 32,
    showOnMobile: false
  },
  {
    text: "最新提出時刻",
    key: "submittedAtTime",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 32,
    showOnMobile: false
  },
  {
    text: "コメント",
    key: "comment",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "disable",
    size: 72,
    showOnMobile: false
  }
];

export const submissionMasterDefinitions: TableDefinition[] = [
  {
    text: "提出物名",
    key: "name",
    type: "string",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 80,
    showOnMobile: true
  },
  {
    text: "対象生徒数",
    key: "studentNum",
    type: "number",
    prefix: false,
    prefixType: "none",
    suffix: false,
    suffixType: "none",
    sort: "normal",
    size: 32,
    showOnMobile: false
  },
  {
    text: "表示開始日",
    key: "submittableFrom",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 32,
    showOnMobile: false
  },
  {
    text: "表示終了日",
    key: "displayEndDate",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 32,
    showOnMobile: false
  },
  {
    text: "締切時刻",
    key: "closedAtDate",
    type: "string",
    prefix: false,
    prefixType: "number",
    suffix: false,
    suffixType: "none",
    sort: "prefix",
    size: 32,
    showOnMobile: false
  }
];
